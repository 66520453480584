import React from "react";
import RootDOM from "./RootDOM";
import { AuthContextProvider } from "./contexts/AuthContext";
import { AlertContextProvider } from "./contexts/AlertContext";
import { AlertContextProviderNew } from "./contexts/AlertContextNew";

//  import './styles.scss'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';  

const App = () => {
  return (
    <AlertContextProvider>
      <AlertContextProviderNew>
        <AuthContextProvider>
          <RootDOM />
        </AuthContextProvider>
      </AlertContextProviderNew>
    </AlertContextProvider>
  );
};

export default App;
