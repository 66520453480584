// Layout.jsx
import React, { useState } from "react";
import { Container } from "react-bootstrap";


// import { useDarkTheme } from "../utility/DarkThemeContext";

const Layout = ({ children }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClick = (menuShow) => {
    setShow(menuShow);
  };

  // const { isDarkTheme } = useDarkTheme();

  return (
    <div >
      {/* <div className="header">
        <Header onHamburgerClick={handleClick} />
      </div> */}
{/* 
      {show ? (
        <LeftMenu
          show={show}
          handleClose={handleClose}
          handleShow={handleShow}
        />
      ) : (
        <></>
      )} */}
      <Container fluid>{children}</Container>
     
    </div>
  );
};

export default Layout;
