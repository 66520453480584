import React, { createContext, useReducer } from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";

export const AlertContext = React.createContext();

export const AlertContextProvider = ({ children }) => {
  const [alerts, setAlerts] = useReducer((state, action) => {
    switch (action.type) {
      case "NEW_ALERT":
        return [action.payload, ...state];
      case "REMOVE_ALERT":
        return state.filter((alert) => alert.id !== action.payload.id);
      default:
        return state;
    }
  }, []);
  const addAlert = (alert) => {
    const id = Date.now();
    setAlerts({ type: "NEW_ALERT", payload: { id, ...alert } });
    setTimeout(() => {
      setAlerts({ type: "REMOVE_ALERT", payload: { id } });
    }, 5000);
  };
  console.log(alerts);
  return (
    <AlertContext.Provider value={{ addAlert }}>
      {children}
      <AlertsWrapper>
        <AnimatePresence>
          {alerts.map((alert) => (
            <Alert
              key={alert.id}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Message>{alert.message}</Message>
            </Alert>
          ))}
        </AnimatePresence>
      </AlertsWrapper>
    </AlertContext.Provider>
  );
};

const AlertsWrapper = styled.div`
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 9999999999999999999999;
`;

const Alert = styled(motion.div)`
  padding: 1.5rem 2.5rem;
  background-color: white;
  border-radius: 3px;
  border: 0.5px solid #e4ebf2;
  width: 30rem;
  margin: 1rem 0;
`;

const Message = styled.p`
  color: #333;
  font-size: 1.6rem;
  font-weight: 700;
`;
