import React, { useEffect, useReducer, useState, useContext,useRef } from "react";
import socketIOClient from "socket.io-client";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

import TableRow from "./TableRow";
import { AuthContext } from "../../contexts/AuthContext";

import { SOCKET_ENDPOINT } from "./../../constants";
import Loading from "../../components/utility/Loading";
import firebase from "../../components/utility/firebase"
// const ENDPOINT = "/";
// const ENDPOINT = "https://singnal-generator.herokuapp.com/";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {tertiaryDatabase,tertiaryApp} from "../../components/utility/firebase"
import { getDatabase, ref, query, orderByKey, limitToLast, onValue, off} from "firebase/database";
import moment from 'moment';


function InstrumentsTable({ setTopTableData }) {
  
  const { auth } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [customSort, setCustomSort] = useState(null);
  const [actionVisible, setActionVisible] = useState(false);
  const socketRef = useRef(null);

  const [data, setData] = useReducer((state, action) => {

    if(action == null || action.type == null)
    return;
    
    switch (action.type) {
      case "UPDATE WHOLE":
        return action.payload;
      case "UPDATE NEW":
        if (!state) {
          return state;
        }
        let currentStocksData = [...state];
        const tick = action.payload;
        for (let i = 0; i < currentStocksData.length; i++) {
          for (let j = 0; j < tick.length; j++) {
            if (
              currentStocksData[i].instrument_token === tick[j].instrument_token
            ) {
              currentStocksData[i] = { ...currentStocksData[i], ...tick[j] };
              break;
            }
          }
        }
        return currentStocksData;
      case "SORT":
        if (customSort) {
          return state;
        }
        if (!state) {
          return state;
        }
        let currentStocks = [...state];
        currentStocks.sort((a, b) => {
          if (!a.signal && !b.signal) {
            return 0;
          }
          if (a.signal && !b.signal) {
            return -1;
          }

          if (!a.signal && b.signal) {
            return 1;
          }

          if (a.volume > 1 && a.volume > b.volume) {
            return -1;
          } else if (b.volume > 1 && b.volume > a.volume) {
            return 1;
          }

          if (a.volumeMultiplier > b.volumeMultiplier) {
            return -1;
          } else if (a.volumeMultiplier < b.volumeMultiplier) {
            return 1;
          }
          const signalGeneratedDateOfA = new Date(
            a.signalGeneratedDate
          ).getTime();
          const signalGeneratedDateOfB = new Date(
            b.signalGeneratedDate
          ).getTime();
          if (signalGeneratedDateOfA > signalGeneratedDateOfB) {
            return -1;
          }
          if (signalGeneratedDateOfA < signalGeneratedDateOfB) {
            return 1;
          }
          return 0;
        });
        return currentStocks;
      case "CUSTOM SORT":
        let currentData = [...state];
        const mode = action.payload.mode;
        const identifier = action.payload.for;
        currentData.sort((a, b) => {
          if (mode === "ascending") {
            if (a[identifier] > b[identifier]) {
              return -1;
            } else return 0;
          } else {
            if (a[identifier] < b[identifier]) {
              return -1;
            } else return 0;
          }
        });
        return currentData;
      default:
        return state;
    }
  }, null);


  
  useEffect(() => {
    let socket = socketIOClient(SOCKET_ENDPOINT, {
    //  transports: ['websocket'],
    //  withCredentials: false,

      query: {
        token: auth.token,
      },
    });

    socket.on('respond', function (data) {
      console.log("Socket responded:" + data);
    });
    

    socket.on("initialData", (data) => {
      console.log("GOT INITIAL DATA");
      const dataArray = Object.keys(data.currentStocksData).map(
        (key) => data.currentStocksData[key]
      );
      setData({ type: "UPDATE WHOLE", payload: dataArray });
      setData({ type: "SORT" });

      const topTableData = Object.keys(data.currentOthersData).map(
        (key) => data.currentOthersData[key]
      );
      setTopTableData({ type: "UPDATE WHOLE", payload: topTableData });
    });

    socket.on("tick", (tick) => {
      console.log("GOT TICK");
      setData({ type: "UPDATE NEW", payload: tick });
    });

    socket.on("othersData", (tick) => {
      console.log("GOT OTHERS DATA");
      setTopTableData({ type: "UPDATE NEW", payload: tick });
    });
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect(true);
      }
    };
  }, [auth.token]);

  useEffect(() => {
    //---------------------firebase------------------------------------//

  

    //-----------------------firebase------------------------------------//

    if (customSort) {
      setData({
        type: "CUSTOM SORT",
        payload: customSort,
      });
      return;
    }
  
    setData({ type: "SORT" });
    // const interval = setInterval(() => {
    //   setData({ type: "SORT" });
    // }, 45000);



    // return () => {
    //   clearInterval(interval);
    // };
  }, [customSort]);



  
//-------------------------------------------------------------------

//----------------------------------------------


// State variable to store the previous data
const [prevData, setPrevData] = useState(null);


//--------------------alerts----------------------//

  useEffect(() => {
    if (customSort) {
      setData({
        type: "CUSTOM SORT",
        payload: customSort,
      });
      return;
    }
    setData({ type: "SORT" });
    const interval = setInterval(() => {
      setData({ type: "SORT" });
    }, 45000);

    return () => {
      clearInterval(interval);
    };
  }, [customSort]);

  if (!data) {
    return null;
  }

  return (
    <Container>


      <TableWrapper>
        <TableContent>
          <TableHeader>
            <TableHeaderItem>Script Name</TableHeaderItem>
            <TableHeaderItem
              onClick={() => {
                if (!customSort || customSort.for !== "volumeMultiplier") {
                  return setCustomSort({
                    for: "volumeMultiplier",
                    mode: "ascending",
                  });
                }
                if (
                  customSort &&
                  customSort.for === "volumeMultiplier" &&
                  customSort.mode === "ascending"
                ) {
                  return setCustomSort({
                    for: "volumeMultiplier",
                    mode: "descending",
                  });
                }
                if (
                  customSort &&
                  customSort.for === "volumeMultiplier" &&
                  customSort.mode === "descending"
                ) {
                  return setCustomSort(null);
                }
              }}
              >
              Vol. Multiplier{" "}
              {customSort && customSort.for === "volumeMultiplier"
                ? customSort.mode === "ascending"
                  ? ChevronDown
                  : ChevronUp
                : null}
            </TableHeaderItem>
            <TableHeaderItem
              onClick={() => {
                if (!customSort || customSort.for !== "last_price") {
                  return setCustomSort({
                    for: "last_price",
                    mode: "ascending",
                  });
                }
                if (
                  customSort &&
                  customSort.for === "last_price" &&
                  customSort.mode === "ascending"
                ) {
                  return setCustomSort({
                    for: "last_price",
                    mode: "descending",
                  });
                }
                if (
                  customSort &&
                  customSort.for === "last_price" &&
                  customSort.mode === "descending"
                ) {
                  return setCustomSort(null);
                }
              }}>
              LTP
              {customSort && customSort.for === "last_price"
                ? customSort.mode === "ascending"
                  ? ChevronDown
                  : ChevronUp
                : null}
            </TableHeaderItem>
            <TableHeaderItem
              onClick={() => {
                if (!customSort || customSort.for !== "change") {
                  return setCustomSort({
                    for: "change",
                    mode: "ascending",
                  });
                }
                if (
                  customSort &&
                  customSort.for === "change" &&
                  customSort.mode === "ascending"
                ) {
                  return setCustomSort({
                    for: "change",
                    mode: "descending",
                  });
                }
                if (
                  customSort &&
                  customSort.for === "change" &&
                  customSort.mode === "descending"
                ) {
                  return setCustomSort(null);
                }
              }}>
              %Change
              {customSort && customSort.for === "change"
                ? customSort.mode === "ascending"
                  ? ChevronDown
                  : ChevronUp
                : null}
            </TableHeaderItem>

            <TableHeaderItem
              onClick={() => {
                if (!customSort || customSort.for !== "change") {
                  return setCustomSort({
                    for: "ohlc.close",
                    mode: "ascending",
                  });
                }
                if (
                  customSort &&
                  customSort.for === "ohlc.close" &&
                  customSort.mode === "ascending"
                ) {
                  return setCustomSort({
                    for: "ohlc.close",
                    mode: "descending",
                  });
                }
                if (
                  customSort &&
                  customSort.for === "ohlc.close" &&
                  customSort.mode === "descending"
                ) {
                  return setCustomSort(null);
                }
              }}
            >
              Previous Closing
              {customSort && customSort.for === "ohlc.close"
                ? customSort.mode === "ascending"
                  ? ChevronDown
                  : ChevronUp
                : null}
            </TableHeaderItem>
            
           
            <TableHeaderItem
              onClick={() => {
                if (!customSort || customSort.for !== "change") {
                  return setCustomSort({
                    for: "change",
                    mode: "ascending",
                  });
                }
                if (
                  customSort &&
                  customSort.for === "change" &&
                  customSort.mode === "ascending"
                ) {
                  return setCustomSort({
                    for: "change",
                    mode: "descending",
                  });
                }
                if (
                  customSort &&
                  customSort.for === "change" &&
                  customSort.mode === "descending"
                ) {
                  return setCustomSort(null);
                }
              }}
            >
              Signal Generation Time
              {customSort && customSort.for === "change"
                ? customSort.mode === "ascending"
                  ? ChevronDown
                  : ChevronUp
                : null}
            </TableHeaderItem>
            <TableHeaderItem>Signal</TableHeaderItem>
          </TableHeader>
          <TableBody>
            {data &&
              data
                 .slice((currentPage - 1) * 71, currentPage * 71)
                .map((eachData) => (
                  <TableRow
                    isPremium={auth.user.isPremium}
                    key={eachData.instrument_token}
                    {...eachData}
                  />
                ))}
          </TableBody>
        </TableContent>
      </TableWrapper>

      
      <PagesContainer>
        {currentPage !== 1 && (
          <>
            <Page onClick={() => setCurrentPage(currentPage - 1)}>
              Previous
            </Page>
            <Page onClick={() => setCurrentPage(currentPage - 1)}>
              {currentPage - 1}
            </Page>
          </>
        )}
        <Page active={true}>{currentPage}</Page>
        {currentPage !== Math.ceil(506 / 71) && (
          <>
            <Page onClick={() => setCurrentPage(currentPage + 1)}>
              {currentPage + 1}
            </Page>
            <Page
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}>
              Next
            </Page>
          </>
        )}
      </PagesContainer>
      <ActionContainer
        onMouseLeave={() => {
          setActionVisible(false);
        }}>
        <AnimatePresence>
          {actionVisible && (
            <ActionsWrapper
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 200, opacity: 0 }}>
              {/* <Action
                onClick={() => {
                  if (!customSort || customSort.for !== "volume") {
                    return setCustomSort({
                      for: "volume",
                      mode: "descending",
                    });
                  }
                  if (
                    customSort &&
                    customSort.for === "volume" &&
                    customSort.mode === "descending"
                  ) {
                    return setCustomSort({
                      for: "volume",
                      mode: "ascending",
                    });
                  }
                  if (
                    customSort &&
                    customSort.for === "volume" &&
                    customSort.mode === "ascending"
                  ) {
                    return setCustomSort(null);
                  }
                }}>
                Sort Based On Volume
                {customSort && customSort.for === "volume"
                  ? customSort.mode === "ascending"
                    ? ChevronDown
                    : ChevronUp
                  : null}
              </Action> */}
              <Action onClick={() => setCustomSort(null)}>
                Sort Based On System Algorithm
              </Action>
            </ActionsWrapper>
          )}
        </AnimatePresence>
        <ActionTrigger
          onClick={() => {
            setActionVisible(!actionVisible);
          }}>
          ?
        </ActionTrigger>
      </ActionContainer>
    </Container>
  );
}

const ActionContainer = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ActionTrigger = styled.p`
  font-size: 1.4rem;
  padding: 1rem;
  border-radius: 10000px;
  height: 5rem;
  width: 5rem;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e4ebf2;
`;

const ActionsWrapper = styled(motion.ul)`
  padding-left: 0;
  list-style: none;
  margin-bottom: 1rem;
  border: 0.5px solid #e4ebf2;
`;

const Action = styled.li`
  padding: 1rem 2rem;
  font-size: 1.4rem;
  color: #333;
  background-color: white;
  transition: background-color 0.2s;

  &:hover {
    background-color: #e4ebf2;
  }
`;

const Container = styled.div`
  background-color: white;
  padding-bottom: 3rem;
  position: relative;
`;

const TableWrapper = styled.div`
  padding: 5.5rem 0 0 0;
  max-width: 120rem;
  margin: 0 auto;
  margin-bottom: 6rem;
  overflow-x: auto;
  white-space: nowrap;
`;

const TableContent = styled.div`
  width: 120rem;
  overflow-x: scroll;
  overflow-y: hidden;
  overflow: hidden;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 14rem 12rem 10rem 10rem 15rem 18rem 1.5fr 3rem;
  grid-gap: 1rem;
  padding: 0.2rem 3rem;
  background-color: #f0f5f8;
`;

const TableHeaderItem = styled.p`
  padding: 1rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  color: #6c7887;
`;

const TableBody = styled.div``;

const PagesContainer = styled.div`
  display: flex;
  margin-bottom: 3rem;
  justify-content: center;
`;

const Page = styled.p`
  padding: 1rem 1.5rem;
  margin: 0 0.5rem;
  border-radius: 0.2rem;
  background-color: ${({ active }) => (active ? "#7c7e80" : "#f0f5f8")};
  color: ${({ active }) => (active ? "white" : "#6c7887")};

  font-size: 1.4rem;
  cursor: pointer;
`;

const ChevronDown = (
  <svg
    style={{ marginLeft: "1rem" }}
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none">
    <path
      d="M10.293 0.292969L6 4.58597L1.707 0.292969L0.292999 1.70697L6 7.41397L11.707 1.70697L10.293 0.292969Z"
      fill="#6c7887"
    />
  </svg>
);

const ChevronUp = (
  <svg
    style={{ marginLeft: "1rem" }}
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none">
    <path
      d="M1.707 7.41406L6 3.12106L10.293 7.41406L11.707 6.00006L6 0.293064L0.293 6.00006L1.707 7.41406Z"
      fill="#6c7887"
    />
  </svg>
);

export default InstrumentsTable;
