import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import moment from "moment";

const getSignalValue = (row) => {
  if (!row.signal) {
    return <Item></Item>;
  }
  if (row.signal.includes("BUY")) {
    return (
      <SignalContainer mode="buy">
        <SignalText mode="buy"> {row.signal} </SignalText>
      </SignalContainer>
    );
  } else if (row.signal.includes("SELL")) {
    return (
      <SignalContainer mode="sell">
        <SignalText mode="sell"> {row.signal} </SignalText>
      </SignalContainer>
    );
  } else if (row.signal.includes("BREAKEVEN")) {
    return (
      <SignalContainer>
        <SignalText>{row.signal} </SignalText>
      </SignalContainer>
    );
  }
};

const TableRow = ({
  instrument_token,
  trading_symbol,
  volume,
  volumeMultiplier,
  last_price,
  change,
  tenDayHigh,
  tenDayLow,
  ohlc,
  breakEven,
  isFno,
  lotSize,
  signal,
  isPremium,
  signalGeneratedDate,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Wrapper>
      <Container key={instrument_token}>
        <Item>{trading_symbol}</Item>
        <Item>{volumeMultiplier}</Item>
        <Item>{last_price}</Item>
        <Item>{change && change.toFixed(2)}</Item>
        <Item>{ohlc && ohlc.close}</Item>
        <Item>
          {signal && moment(signalGeneratedDate).format("hh : mm : ss A")}
        </Item>
        {getSignalValue({ signal, tenDayHigh, tenDayLow, ohlc })}
        <IconWrapper
          onClick={() => {
            if (!isPremium) {
              alert("You must be a premium member to use this functionality");
              return;
            }
            setIsExpanded(!isExpanded);
          }}>
          {isExpanded ? (
            <SVG width="10" height="10" viewBox="0 0 15 15" fill="none">
              <path
                d="M8.73655 7.5L14.9548 0.30138C15.059 0.181749 14.9714 0 14.8103 0H12.92C12.8086 0 12.702 0.0483128 12.6286 0.131135L7.5 6.06902L2.37142 0.131135C2.30035 0.0483128 2.19375 0 2.08005 0H0.189699C0.0286162 0 -0.0590318 0.181749 0.0451981 0.30138L6.26346 7.5L0.0451981 14.6986C0.0218496 14.7253 0.00687073 14.7579 0.00203912 14.7926C-0.00279249 14.8273 0.00272616 14.8626 0.0179404 14.8943C0.0331547 14.926 0.0574256 14.9529 0.0878709 14.9716C0.118316 14.9903 0.153657 15.0002 0.189699 15H2.08005C2.19139 15 2.29798 14.9517 2.37142 14.8689L7.5 8.93098L12.6286 14.8689C12.6996 14.9517 12.8062 15 12.92 15H14.8103C14.9714 15 15.059 14.8182 14.9548 14.6986L8.73655 7.5Z"
                fill="#6c7887"
              />
            </SVG>
          ) : (
            <SVG width="14" height="10" viewBox="0 0 21 14" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.9842 8.4H1.03336C0 8.4 0 7.826 0 7C0 6.174 0 5.6 1.03336 5.6H19.9666C21 5.6 21 6.174 21 7C21 7.826 21 8.4 19.9666 8.4H19.9842ZM19.9842 2.8H1.03336C0 2.8 0 2.226 0 1.4C0 0.574 0 0 1.03336 0H19.9666C21 0 21 0.574 21 1.4C21 2.226 21 2.8 19.9666 2.8H19.9842ZM1.03336 11.2H19.9666C21 11.2 21 11.774 21 12.6C21 13.426 21 14 19.9666 14H1.03336C0 14 0 13.426 0 12.6C0 11.774 0 11.2 1.03336 11.2Z"
                fill="#6c7887"
              />
            </SVG>
          )}
        </IconWrapper>
      </Container>
      <AnimatePresence>
        {isExpanded && (
          <ExpandableContainer
            initial={{ height: 0, borderBottomWidth: 0 }}
            animate={{ height: "auto", borderBottomWidth: 0.5 }}
            exit={{ height: 0, borderBottomWidth: 0 }}>
            <Column>
              <KeyValuePairs>
                <Key>10 Day High : </Key>
                <Value>{tenDayHigh}</Value>
              </KeyValuePairs>
              <KeyValuePairs>
                <Key>10 Day Low : </Key>
                <Value>{tenDayLow}</Value>
              </KeyValuePairs>
            </Column>
            <Column>
              <KeyValuePairs>
                <Key>Day High : </Key>
                <Value>{ohlc.high}</Value>
              </KeyValuePairs>
              <KeyValuePairs>
                <Key>Day Low : </Key>
                <Value>{ohlc.low}</Value>
              </KeyValuePairs>
            </Column>
            <Column>
              <KeyValuePairs>
                <Key>Today's Volume : </Key>
                <Value>{(volume / 1000000).toFixed(3)}</Value>
              </KeyValuePairs>
              {isFno && (
                <KeyValuePairs>
                  <Key>Lot Size : </Key>
                  <Value>{lotSize}</Value>
                </KeyValuePairs>
              )}
            </Column>
            <Column>
              <KeyValuePairs>
                <Key>Breakeven</Key> <br />
                <Value>{breakEven}</Value>
              </KeyValuePairs>
            </Column>
          </ExpandableContainer>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

const Container = styled.div`
  display: grid;
  height: 4.5rem;
  grid-template-columns: 14rem 12rem 10rem 10rem 15rem 18rem 1.5fr 3rem;
  grid-gap: 1rem;
  padding: 0rem 3rem;
  border-bottom: 0.5px solid #e4ebf2;
`;

const Item = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #373b46;
`;

const SVG = styled.svg`
  cursor: pointer;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SignalContainer = styled.div`
  margin: 0.2rem 0;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.2rem;
`;

const SignalText = styled.span`
  background-color: ${({ mode }) => {
    if (mode === "buy") {
      return "#EDF5F5";
    } else if (mode === "sell") {
      return "#FFEFEF";
    } else {
      return "rgba(255, 165, 0, 0.1)";
    }
  }};
  padding: 0.5rem 1rem;
  color: ${({ mode }) => {
    if (mode === "buy") {
      return "#04A780";
    } else if (mode === "sell") {
      return "#AD0000";
    } else {
      return "#B67600";
    }
  }};
`;

const Wrapper = styled.div``;

const ExpandableContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  padding: 0rem 3rem;
  border-bottom-color: #e4ebf2;
  border-bottom-style: solid;
  overflow: hidden;
`;

const Column = styled.div`
  padding: 1rem;
  &:not(:last-child) {
    border-right: 0.5px solid #e4ebf2;
  }
`;

const KeyValuePairs = styled.p`
  padding: 0.5rem 0;
  text-align: center;
`;

const Key = styled.span`
  font-weight: 200;
  font-size: 1.4rem;
  line-height: 21px;

  color: #373b46;
`;

const Value = styled.span`
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 21px;

  color: #373b46;
`;

export default TableRow;
