import React, { useRef, useState } from 'react';
import { Button, Input, Space, Table } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import moment from "moment";
import Highlighter from 'react-highlight-words';


const SignalsTable = ({ signals, showCe }) => {
   console.log(signals  + {signals} + showCe );

  const data = signals;

  
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div
      style={{
        padding: 8,
      }}
    >
      <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters)}
          size="small"
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({
              closeDropdown: false,
            });
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
        >
          Filter
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined
      style={{
        color: filtered ? '#1890ff' : undefined,
      }}
    />
  ),
  onFilter: (value, record) =>
    record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100);
    }
  },
  render: (text) =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{
          backgroundColor: '#ffc069',
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ),
});



  const columns = [
    {
      title: "Script",
      dataIndex: "tradingSymbol",
      key: "tradingSymbol",
      render: function (text) {
        return <p>{text.match(/[a-z]+|[^a-z]+/gi).join(" ")}</p>;
      },
      ...getColumnSearchProps('tradingSymbol'),
      sorter: {
        compare: (a, b) => (a.tradingSymbol < b.tradingSymbol ? true : false),
        multiple: 1,
      },

      
   
      
    },
    {
      title: "Signal",
      dataIndex: "signal",
      key: "signal",

      filters: [
        {
          text: 'NIFTY',
          value: 'NIFTY',
        },
        {
          text: 'BANKNIFTY',
          value: 'BANKNIFTY',
        },
        {
          text: 'CRUDE',
          value: 'CRUDE',
        },
  
        
  
      ],
  
      onFilter: (value, record) => record.tradingSymbol.startsWith(value),
    },
  
    {
      title: "Type",
      dataIndex: "instrumentType",
      key: "instrumentType",
     

      filters: [
        {
          text: 'CE',
          value: 'CE',
        },
        {
          text: 'PE',
          value: 'PE',
        },
  
        
  
      ],
  
      onFilter: (value, record) => record.instrumentType.startsWith(value),
    },
  
    
    
    {
      title: "Signal Generated Date",
      dataIndex: "signalGeneratedDate",
      key: "signalGeneratedDate",
      render: (text) => <p>{moment(new Date(text)).format("hh:mm:ss A")}</p>,
      sorter: {
        compare: (a, b) => a.signalGeneratedDate - b.signalGeneratedDate,
        multiple: 1,
      },
    },
  ];
 

  return (
    <div style={{ margin: "20px auto", width: "70vw" }}>
      <Table
        rowKey="tradingSymbol"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </div>
  );
};

export default SignalsTable;