import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase, ref, set } from "firebase/database";

// import firebase from 'firebase/compat/app';
// import "firebase/compat/database";


const firebaseConfig = {

    apiKey: "AIzaSyApRnPJYGeehQ6AQZsTblKz_LpjErj00gU",
    authDomain: "algo-auto.firebaseapp.com",
    databaseURL: "https://algo-auto-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "algo-auto",
    storageBucket: "algo-auto.appspot.com",
    messagingSenderId: "32373785341",
    appId: "1:32373785341:web:d4074d5d16166c3636f494",
    measurementId: "G-3G4TYY0C3X"
};


const secondaryAppConfig = {
    apiKey: "AIzaSyCk9ywn-nHcS3VGqlfz357o1cf20OP8FLQ",
    authDomain: "scanners-f7236.firebaseapp.com",
    databaseURL: "https://scanners-f7236-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "scanners-f7236",
    storageBucket: "scanners-f7236.appspot.com",
    messagingSenderId: "821735177847",
    appId: "1:821735177847:web:718ea5c1b7edb8f4f41072",
    measurementId: "G-DCPBT8NHW7"
};




const tertiaryAppConfig = {
    apiKey: "AIzaSyDL_RxPwUIgQ5sOxjmrNaiClqCtMxYl8FQ",
    authDomain: "chartink-alerts-10c2e.firebaseapp.com",
    databaseURL: "https://chartink-alerts-10c2e-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "chartink-alerts-10c2e",
    storageBucket: "chartink-alerts-10c2e.appspot.com",
    messagingSenderId: "365836366317",
    appId: "1:365836366317:web:d3f5fc8b44e775eae377e4",
    measurementId: "G-P40PD6QJ1L"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
export const database = getDatabase(app);



const secondaryApp = initializeApp(secondaryAppConfig, "secondary");
export const secondaryDatabase = getDatabase(secondaryApp);


export const tertiaryApp = initializeApp(tertiaryAppConfig, "tertiary");

export const tertiaryDatabase = getDatabase(tertiaryApp);


export default app;