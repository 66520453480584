import React from "react";

const LoginImage = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 722.5 729.7"
      style={{ enableBackground: "new 0 0 722.5 729.7" }}
    >
      <path
        class="st0"
        d="M266.7,104.7c0-46.9,38-84.9,84.9-84.9c46.9,0,84.9,38,84.9,84.9s-38,84.9-84.9,84.9c0,0,0,0,0,0
      C304.7,189.6,266.7,151.6,266.7,104.7C266.7,104.7,266.7,104.7,266.7,104.7z"
      />
      <path d="M76,712.4l-24.7,14.6v3.6h48.3v-18.2L76,712.4z" />
      <path d="M202.8,713.4h23.7v16.3h-23.7V713.4z" />
      <path class="st1" d="M15,454.3l88.1,26.2l-15,50.6L0,504.9L15,454.3z" />
      <path
        class="st2"
        d="M90.8,377.1L74.9,714l26.8,0.2l54.8-220.4l-1.3-109.7L90.8,377.1z"
      />
      <path
        class="st3"
        d="M229.6,376.5c-0.4,9.6-1.5,338.2-1.5,338.2l-26.1,0.2l-81.2-330.1C120.8,384.7,230,366.9,229.6,376.5z"
      />
      <path
        class="st4"
        d="M66.7,284c-5.4,9.1-18.4,78.1-18.3,94.2c-4.2,14.5-0.8,57.9-0.7,63.7s-1,27.3,0.2,32.6
      c-3.3,8.4,13.3,25.7,18.4,26.2s11.1-8.5,11.1-12.8c0-10.9-7.1-20.2-7-24.7c0.7-22.6,10.5-80.1,11.7-84s5.4-11.4,8.9-14.5
      s5.2-70.2,5.2-70.2S69.6,279.1,66.7,284z"
      />
      <path
        class="st4"
        d="M137.8,167.7c0,2.8,1.7,28.9-1.6,34.8s32.8,3.9,32.8-0.6s-1.2-25,1.7-32.5S137.8,164.8,137.8,167.7z"
      />
      <path
        class="st4"
        d="M260.6,244.9l5.3,3.8c0,0,45.4-87.8,45.9-91s-3-11-2.5-14.7s18.8-21.8,24.5-22.1s6.2,2.5,4.8,5.3
      s-12.3,13.9-12.3,13.9s7.6-2.1,10.2,0s12.7,15.5,8.9,21.4s-11,8.8-12.4,12.3s-44.5,124.7-49.8,127.1c-8.9,4-37.8-10-42.1-16.9
      S260.6,244.9,260.6,244.9z"
      />
      <path
        class="st5"
        d="M120.5,207c29.6,10.7,10.4,89.5,0.9,95c-7.5,4.3-61.9-12.8-63.8-21.1C54.9,268.8,94.7,197.7,120.5,207z"
      />
      <path
        class="st6"
        d="M157.7,192.8c76.1-0.3,83.5,173.4,70.8,185.3c-23,21.4-133.6,11.8-138.5,0.5C82.1,359.9,85.8,193,157.7,192.8z"
      />
      <path
        class="st7"
        d="M168.8,223.5c13.1-28.7,90.1-2.9,94.7,7c3.7,7.8-18,60.6-26.3,61.8C224.9,294,157.4,248.4,168.8,223.5z"
      />
      <path
        class="st4"
        d="M124.6,130.6c0-24.9,15.4-45,34.4-45s34.4,20.2,34.4,45s-15.4,45.1-34.4,45.1S124.6,155.5,124.6,130.6z"
      />
      <path
        class="st8"
        d="M113.3,124.8c-1.3-18.8,9.6-36.3,13.5-47.3c3.3-10.6,10-18.4,18.6-18.8s11,1.8,16.7,1.6s15.7-6.7,22.2-6.7
      s10.3,4.6,14.3,7.5s12.3,8.4,12.2,13.2c-0.2,28.9-26.6,26.2-34.3,34.5c-5.2,4.4-5.5,14.6-8.1,16.3s-8.3,0.7-8.4,8.9
      c-0.1,4.8,1.3,8.8,4.7,9.1s4.7,1.6,4.6,4.1c-0.7,24.2-17.8,28.8-24.8,28.1c-9.8-0.9-12.5-12.9-15-16.9
      C120.3,148,114,136.6,113.3,124.8z"
      />
      <path
        class="st9"
        d="M347.5,108.4l12.7-54.2L373,0l24.6,12.9l24.6,12.9l-37.3,41.3L347.5,108.4z"
      />
      <path class="st0" d="M478.3,24.4h244.2v150.2H478.3V24.4z" />
      <path class="st9" d="M493,36.5h21.7v126H493V36.5z" />
      <path class="st9" d="M525.2,54.8h21.7v107.8h-21.7V54.8z" />
      <path class="st9" d="M557.4,82.5H579v80h-21.7V82.5z" />
      <path class="st9" d="M589.6,120.1h21.7v42.4h-21.7V120.1z" />
      <path class="st9" d="M621.7,83.6h21.7v78.9h-21.7V83.6z" />
      <path class="st9" d="M653.9,56.2h21.7v106.4h-21.7V56.2z" />
      <path class="st9" d="M686.1,36.5h21.7v126h-21.7V36.5z" />
      <path class="st10" d="M362.9,319.5l45.9-45.9" />
      <path class="st10" d="M434.7,298l49.3-49.3" />
      <path class="st10" d="M509.6,272.5l10.7-10.7" />
      <path class="st10" d="M562.1,303l64-64" />
      <path class="st10" d="M408.9,272.9l24.6,24.6" />
      <path class="st10" d="M484,249.3l24.4,24.4" />
      <path class="st10" d="M520.9,261.3l41.3,41.3" />
      <path class="st10" d="M627.8,237.5l15.9,15.9" />
      <path
        class="st9"
        d="M406.3,273.7c0-1.5,1.2-2.8,2.8-2.8s2.8,1.2,2.8,2.8s-1.2,2.8-2.8,2.8S406.3,275.2,406.3,273.7L406.3,273.7z"
      />
      <path
        class="st9"
        d="M431.5,297.1c0-1.5,1.2-2.8,2.8-2.8s2.8,1.2,2.8,2.8s-1.2,2.8-2.8,2.8l0,0C432.7,299.8,431.5,298.6,431.5,297.1
      z"
      />
      <path
        class="st9"
        d="M481,250.1c0-1.5,1.2-2.8,2.8-2.8c1.5,0,2.8,1.2,2.8,2.8c0,1.5-1.2,2.7-2.8,2.7c0,0,0,0,0,0
      C482.2,252.8,481,251.6,481,250.1z"
      />
      <path
        class="st9"
        d="M506.2,273c0-1.5,1.2-2.8,2.8-2.8s2.8,1.2,2.8,2.8s-1.2,2.8-2.8,2.8S506.2,274.5,506.2,273L506.2,273z"
      />
      <path
        class="st9"
        d="M518.1,261.4c0-1.5,1.2-2.8,2.8-2.8s2.8,1.2,2.8,2.8s-1.2,2.8-2.8,2.8l0,0C519.3,264.2,518.1,263,518.1,261.4z"
      />
      <path
        class="st9"
        d="M559.6,301.9c0-1.5,1.2-2.8,2.8-2.8s2.8,1.2,2.8,2.8s-1.2,2.8-2.8,2.8S559.6,303.4,559.6,301.9L559.6,301.9z"
      />
      <path
        class="st9"
        d="M625,238.3c0-1.5,1.2-2.8,2.8-2.8s2.8,1.2,2.8,2.8s-1.2,2.8-2.8,2.8S625,239.8,625,238.3L625,238.3z"
      />
    </svg>
  );
};

export default LoginImage;
