import React from "react";
import styled from "styled-components";
import Table from "./Table";
import "./index.scss";
import { ToastContainer, toast } from 'react-toastify';

const Home = () => {
  return (
    <HomeContainer>



{/* Same as */}
<ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>
      <Table />
     
    </HomeContainer>
  );
};

const HomeContainer = styled.div``;

export default Home;
