import React, { createContext, useReducer } from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";

export const AlertContextNew = React.createContext();

export const AlertContextProviderNew = ({ children }) => {
  const [alerts, setAlerts] = useReducer((state, action) => {
    switch (action.type) {
      case "NEW_ALERT":
        return [action.payload, ...state];
      case "REMOVE_ALERT":
        return state.filter((alert) => alert.id !== action.payload.id);
      default:
        return state;
    }
  }, []);
  const addAlert = (alert) => {
    const id = Date.now();
    setAlerts({ type: "NEW_ALERT", payload: { id, ...alert } });
    setTimeout(() => {
      setAlerts({ type: "REMOVE_ALERT", payload: { id } });
    }, 5000);
  };
  console.log(alerts);
  return (
    <AlertContextNew.Provider value={{ addAlert }}>
      {children}
      <AlertsWrapper>
        <AnimatePresence>
          {alerts.map((alert) => (
            <Alert
              key={alert.id}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Message>{alert.message}</Message>
            </Alert>
          ))}
        </AnimatePresence>
      </AlertsWrapper>
    </AlertContextNew.Provider>
  );
};

const AlertsWrapper = styled.div`
  position: fixed;
  right: 4rem;
  bottom: 4rem;
  z-index: 9999999999999999999999;
`;

const Alert = styled(motion.div)`
  padding: 1.5rem 2.5rem;
  background-color: #f5f5f5;
  border-radius: 3px;
  border: 1px solid #222023;
  width: 50rem;
  margin: 1rem 0;
`;

const Message = styled.p`
  color: #333;
  font-size: 1.6rem;
  font-weight: 700;
`;
