import React, { useReducer, useEffect, useContext } from "react";
import styled from "styled-components";
import Table from "./Table";
import TopTable from "./TopTable";
//import { messaging } from "../../push-notification";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import { SERVER_URI } from "../../constants";
import Loading from "../../components/utility/Loading";
import firebase from "../../components/utility/firebase"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Home = () => {
  const notify = () => toast("Wow so easy!");
  //const analytics = firebase.analytics();
  
 // const [count, setCount] = useStateIfMounted(0);
  const { auth } = useContext(AuthContext);
  // analytics.setUserId( `${auth.user.name.split(" ")[0]}`)
  // analytics.setUserProperties({username: ` ${auth.user.name.split(" ")[0]}`});
  // analytics.logEvent('user_view', ` ${auth.user.name.split(" ")[0]}`);

  const [topTableData, setTopTableData] = useReducer((state, action) => {
    switch (action.type) {
      case "UPDATE WHOLE":
        return action.payload;
      case "UPDATE NEW":
        if (!state) {
          return state;
        }
        let currentStocksData = [...state];
        const tick = action.payload;
        for (let i = 0; i < currentStocksData.length; i++) {
          for (let j = 0; j < tick.length; j++) {
            if (
              currentStocksData[i].instrument_token === tick[j].instrument_token
            ) {
              currentStocksData[i] = { ...currentStocksData[i], ...tick[j] };
              break;
            }
          }
        }
        return currentStocksData;
      default:
        return state;
    }
  }, null);
  
 // console.log("topTableData: "+ JSON.stringify(topTableData));
  return (
    <HomeContainer>
       {topTableData ? <TopTable topTableData={topTableData} /> : <Loading />}

      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>
      <Table setTopTableData={setTopTableData} />
      
    </HomeContainer> 
  );
};

const HomeContainer = styled.div``;

export default Home;
