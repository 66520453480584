import React from "react";
import ReportTable from "./ReportTable";
import styled from "styled-components";

const Reports = ({ auth, date }) => {
  return (
    <ReportContainer>
      <ReportTable auth={auth} date={date} />
    </ReportContainer>
  );
};

const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 3rem 0;
`;

export default Reports;
