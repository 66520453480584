import { Space, Spin } from "antd";
import { useEffect, useReducer, useRef, useState,useContext } from "react";
import socketIOClient from "socket.io-client";
import styled from "styled-components";
import "./index.scss";
import { AuthContext } from "../../contexts/AuthContext";


import {app,database} from "../../components/utility/firebase"

import {  ref, onValue, get, child} from "firebase/database";
import TableRow from "./TableRow";

//const SOCKET_ENDPOINT = "http://139.59.90.225";

const SOCKET_ENDPOINT = "http://localhost:5000";


const Home = () => {

  const { auth } = useContext(AuthContext);
  
  const todoRef = database;
  const refrenceForBBCDB = ref(todoRef, '/users/scriptdata/');
  const refrenceForCount = ref(todoRef, 'PCRDB/BBC_COUNT/');
  const [todoList, setTodoList] = useState({ status: "loading", data: null, error: null });
  const [ceCount, setceCount] = useState(0)
  const [peCount, setpeCount] = useState(0)
  //let firebase = [];

  const [data, setData] = useReducer(
    (state, action) => {
      switch (action.type) {
       

        default:
          return state;
      }
    },
    {
      possibleMonthYearCombo: null,
      signals: [],
    
      selectedMonthYearCombo: null,
      showCe: true,
      count: [],
     CE : 0,
     PE :0 
    }
  );

  const socketRef = useRef();

  useEffect(() => {


    
//Firebase code
 
const listener = onValue(refrenceForBBCDB, (snapshot) => {
  //const data = snapshot.val();
  const todos = snapshot.val();
  console.log(todos)
 
        const todoList = [];
      for (let id in todos) {

        console.log(todos[id])
        todoList.push({...todos[id]})
       
      }
      console.log(todoList)
      
      setTodoList(todoList);

      console.log("*******************************")
  
});


return listener;
  




  }, []);

  const {
    possibleMonthYearCombo,
    signals,
    
    selectedMonthYearCombo,
    showCe,
    count,
   CE,
   PE
  } = data;


  if (!todoList) {
    return (
      <Space
        style={{ height: "100vh", width: "100%", justifyContent: "center" }}
        align="center"
        direction="vertical">
        <Spin size="large" />
      </Space>
    );
  }
 

 
  switch (todoList.status) {
    case "loading":
      return null; // hides component, could also show a placeholder/spinner
    case "error":
      return (
        <div class="error">
          Failed to retrieve data: {todoList.error.message}
        </div>
      );
  }


  
  return (
    <Container>
      <TableWrapper>
        <TableContent>
          <TableHeader>
            <TableHeaderItem>Script Name</TableHeaderItem>
            <TableHeaderItem
              // onClick={() => {
              //   if (!customSort || customSort.for !== "volumeMultiplier") {
              //     return setCustomSort({
              //       for: "volumeMultiplier",
              //       mode: "ascending",
              //     });
              //   }
              //   if (
              //     customSort &&
              //     customSort.for === "volumeMultiplier" &&
              //     customSort.mode === "ascending"
              //   ) {
              //     return setCustomSort({
              //       for: "volumeMultiplier",
              //       mode: "descending",
              //     });
              //   }
              //   if (
              //     customSort &&
              //     customSort.for === "volumeMultiplier" &&
              //     customSort.mode === "descending"
              //   ) {
              //     return setCustomSort(null);
              //   }
              // }}
              >
              Signal{" "}
              {/* {customSort && customSort.for === "volumeMultiplier"
                ? customSort.mode === "ascending"
                  ? ChevronDown
                  : ChevronUp
                : null} */}
            </TableHeaderItem>
            <TableHeaderItem
              // onClick={() => {
              //   if (!customSort || customSort.for !== "last_price") {
              //     return setCustomSort({
              //       for: "last_price",
              //       mode: "ascending",
              //     });
              //   }
              //   if (
              //     customSort &&
              //     customSort.for === "last_price" &&
              //     customSort.mode === "ascending"
              //   ) {
              //     return setCustomSort({
              //       for: "last_price",
              //       mode: "descending",
              //     });
              //   }
              //   if (
              //     customSort &&
              //     customSort.for === "last_price" &&
              //     customSort.mode === "descending"
              //   ) {
              //     return setCustomSort(null);
              //   }
              // }}
              >
              Time
              {/* {customSort && customSort.for === "last_price"
                ? customSort.mode === "ascending"
                  ? ChevronDown
                  : ChevronUp
                : null} */}
            </TableHeaderItem>
           
            {/* <TableHeaderItem>Signal</TableHeaderItem> */}
          </TableHeader>
          <TableBody>
            {todoList &&
              todoList
                 
                .map((eachData) => (
                  <TableRow
                    isPremium={auth.user.isPremium}
                    key={eachData.name}
                    {...eachData}
                  />
                ))}
          </TableBody>
        </TableContent>
      </TableWrapper>

      
      
     
    </Container>
  );
}

const ActionContainer = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ActionTrigger = styled.p`
  font-size: 1.4rem;
  padding: 1rem;
  border-radius: 10000px;
  height: 5rem;
  width: 5rem;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e4ebf2;
`;



const Action = styled.li`
  padding: 1rem 2rem;
  font-size: 1.4rem;
  color: #333;
  background-color: white;
  transition: background-color 0.2s;

  &:hover {
    background-color: #e4ebf2;
  }
`;

const Container = styled.div`
  background-color: white;
  padding-bottom: 3rem;
  position: relative;
`;

const TableWrapper = styled.div`
  padding: 5.5rem 0 0 0;
  max-width: 120rem;
  margin: 0 auto;
  margin-bottom: 6rem;
  overflow-x: auto;
  white-space: nowrap;
`;

const TableContent = styled.div`
  width: 120rem;
  overflow-x: scroll;
  overflow-y: hidden;
  overflow: hidden;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 14rem 28rem 14rem ;
  grid-gap: 5rem;
  padding: 0.2rem 3rem;
  background-color: #f0f5f8;
`;

const TableHeaderItem = styled.p`
  padding: 1rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: left;
  color: #6c7887;
  justify-content: left;
`;

const TableBody = styled.div``;

const PagesContainer = styled.div`
  display: flex;
  margin-bottom: 3rem;
  justify-content: center;
`;

const Page = styled.p`
  padding: 1rem 1.5rem;
  margin: 0 0.5rem;
  border-radius: 0.2rem;
  background-color: ${({ active }) => (active ? "#7c7e80" : "#f0f5f8")};
  color: ${({ active }) => (active ? "white" : "#6c7887")};

  font-size: 1.4rem;
  cursor: pointer;
`;

const ChevronDown = (
  <svg
    style={{ marginLeft: "1rem" }}
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none">
    <path
      d="M10.293 0.292969L6 4.58597L1.707 0.292969L0.292999 1.70697L6 7.41397L11.707 1.70697L10.293 0.292969Z"
      fill="#6c7887"
    />
  </svg>
);

const ChevronUp = (
  <svg
    style={{ marginLeft: "1rem" }}
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none">
    <path
      d="M1.707 7.41406L6 3.12106L10.293 7.41406L11.707 6.00006L6 0.293064L0.293 6.00006L1.707 7.41406Z"
      fill="#6c7887"
    />
  </svg>
);

export default Home;