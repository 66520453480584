import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import moment from "moment";

const getSignalValue = (row) => {
  console.log(row)
  if (!row.signal) {
    return <Item></Item>;
  }
  if (row.signal.includes("BUY")) {
    return (
      <SignalContainer mode="buy">
        <SignalText mode="buy"> {row.signal} </SignalText>
      </SignalContainer>
    );
  } else if (row.signal.includes("SELL")) {
    return (
      <SignalContainer mode="sell">
        <SignalText mode="sell"> {row.signal} </SignalText>
      </SignalContainer>
    );
  } else if (row.signal.includes("BREAKEVEN")) {
    return (
      <SignalContainer>
        <SignalText>{row.signal} </SignalText>
      </SignalContainer>
    );
  }
};

const TableRow = ({
  name,
  pattern,
  signal,
  isPremium,
  signalGeneratedDate,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Wrapper>
      <Container key={name}>
        <Item>{name}</Item>
        <Item>{pattern}</Item>
        {/* <Item>{last_price}</Item>
        <Item>{change && change.toFixed(2)}</Item>
        <Item>{ohlc && ohlc.close}</Item> */}
        <Item>
          {signal && moment(signalGeneratedDate).format("hh : mm : ss A")}
        </Item>
       
      </Container>
     
    </Wrapper>
  );
};

const Container = styled.div`
  display: grid;
  height: 4.5rem;
  
  grid-template-columns: 14rem 28rem 14rem ;
  grid-gap: 5rem;
  padding: 0rem 3rem;
  border-bottom: 0.5px solid #e4ebf2;
`;

const Item = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: left;
  color: #373b46;
`;

const SVG = styled.svg`
  cursor: pointer;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SignalContainer = styled.div`
  margin: 0.2rem 0;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.2rem;
`;

const SignalText = styled.span`
  background-color: ${({ mode }) => {
    if (mode === "buy") {
      return "#EDF5F5";
    } else if (mode === "sell") {
      return "#FFEFEF";
    } else {
      return "rgba(255, 165, 0, 0.1)";
    }
  }};
  padding: 0.5rem 1rem;
  color: ${({ mode }) => {
    if (mode === "buy") {
      return "#04A780";
    } else if (mode === "sell") {
      return "#AD0000";
    } else {
      return "#B67600";
    }
  }};
`;

const Wrapper = styled.div``;


const Column = styled.div`
  padding: 1rem;
  &:not(:last-child) {
    border-right: 0.5px solid #e4ebf2;
  }
`;

const KeyValuePairs = styled.p`
  padding: 0.5rem 0;
  text-align: center;
`;

const Key = styled.span`
  font-weight: 200;
  font-size: 1.4rem;
  line-height: 21px;

  color: #373b46;
`;

const Value = styled.span`
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 21px;

  color: #373b46;
`;

export default TableRow;
