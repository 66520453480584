import React, { useContext } from "react";
import { Router } from "@reach/router";
import Navigation from "./components/Navigation";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Reports from "./pages/Reports";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import SignUp from "./pages/SignUp";
import Settings from "./pages/Settings";
import { AuthContext } from "./contexts/AuthContext";
import styled from "styled-components";
import Fno from "./pages/Fno";
import HeavyWeight from "./pages/HeavyWeight";
import  Marabazou from "./pages/Marabazou";
import  OptionCall from "./pages/OptionCall";
import PositionalCall from "./pages/PositionalCall"
import WatchList from "./pages/WatchList";
import StockAction from "./pages/Dashboard/StockAction"
const RootDOM = () => {
  const { auth } = useContext(AuthContext);

  if (!auth) {
    return (
      <Router>
        <Login path="/" />
        <ForgotPassword path="/forgot-password" />
        <ResetPassword path="/reset-password" />
        <SignUp path="/sign-uP" /> 
      
      
      </Router>
      
    );
  }
   { <SignUp path="/sign-up" /> } //todo change signup
  return (
    <MainWrapper>
      <Navigation />
      <Router>
         <StockAction path="/stock-action" />
        <Home path="/" />
        <Reports path="/reports/*" />
        <Settings path="/settings/*" />
        <Fno path="/fno" />
        <HeavyWeight path="/heavy-weight" />
        <Marabazou path="/marabazou-scanner" />
        <OptionCall path="/option-buy-signals" />
        <PositionalCall path="/positional"/>
        <WatchList path="/watch-list" />
      </Router>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  background-color: #fafcfd;
`;

export default RootDOM;
