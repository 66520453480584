import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import 'antd/dist/antd.min.css';
// import './styles.scss'

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'primereact/resources/themes/lara-light-indigo/theme.css';
// import 'primereact/resources/primereact.css';  

ReactDOM.render(<App />, document.getElementById("root"));
