import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Table from "./Table";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Home = () => {
  return (
    <HomeContainer>

<ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>

      <Table />
    </HomeContainer>
  );
};

const HomeContainer = styled.div``;

export default Home;
