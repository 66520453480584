import React, { useState, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import { AuthContext } from "../../contexts/AuthContext";
import { SERVER_URI } from "../../constants";
import Loading from "../../components/utility/Loading";
import LoginImage from "./../Login/LoginImage";
import { Link } from "@reach/router";
import validator from "validator";

const SignUp = (props) => {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const [alert, setAlert] = useState(null);

  const [loading, setLoading] = useState(false);
  const { login } = useContext(AuthContext);

  const handleSignUp = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const {
        data: { token, user },
      } = await axios.post(`${SERVER_URI}/users/public`, {
        ...form,
      });
      props.navigate("/");
      login({ user, token });
      setAlert({ message: "Sign Up Success !!" });
    } catch (err) {
      if (
        err?.response?.data &&
        Object.values(err?.response?.data).length > 0
      ) {
        let msg = "| ";
        Object.values(err.response.data).forEach((val) => {
          msg += `${val} | `;
        });
        setAlert({
          message: msg,
        });
      } else {
        setAlert({
          message: "Provide Valid Credentials",
        });
      }
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <LeftSide>
        <Title>Welcome</Title>
        <LoginForm onSubmit={handleSignUp}>
          <LoginTitle>
            Sign Up To <br /> Vijay Wealth Investor
          </LoginTitle>
          <FormGroup>
            <Label>Name</Label>
            <Input
              placeholder="Name"
              value={form.name}
              onChange={(e) => {
                setForm({ ...form, name: e.target.value });
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <Input
              placeholder="Email"
              value={form.email}
              onChange={(e) => {
                setForm({ ...form, email: e.target.value });
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Phone Number</Label>
            <Input
              placeholder="Phone Number"
              value={form.phoneNumber}
              onChange={(e) => {
                setForm({ ...form, phoneNumber: e.target.value });
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>City</Label>
            <Input
              placeholder="City"
              value={form.city}
              onChange={(e) => {
                setForm({ ...form, city: e.target.value });
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Password</Label>
            <Input
              type="password"
              placeholder="Password"
              value={form.password}
              onChange={(e) => {
                setForm({ ...form, password: e.target.value });
              }}
            />
          </FormGroup>

          {loading ? (
            <Loading />
          ) : (
            <>
              <SubmitButton
                value="Sign Up"
                type="submit"
                onClick={handleSignUp}
              />
            </>
          )}
          {alert && (
            <Alert
              key={alert.id}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}>
              <Message>{alert.message}</Message>
            </Alert>
          )}
        </LoginForm>

        <LoginFooter>
          <Link
            to="/forgot-password"
            style={{
              display: "block",
              marginBottom: "1.5rem",
              fontSize: "1.2rem",
              color: "grey",
              textAlign: "center",
            }}>
            Forgot Your Password ?
          </Link>
          <Link
            to="/"
            style={{
              display: "block",
              marginBottom: "1rem",
              fontSize: "1.2rem",
              color: "grey",
              textAlign: "center",
            }}>
            Already Have An Account ? Log In !
          </Link>
        </LoginFooter>
      </LeftSide>
      <RightSide>
        <LoginImage />
      </RightSide>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 auto;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 0.8fr 1fr;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const RightSide = styled.div`
  background-color: #fcfcfc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;

  svg {
    width: 60%;
  }
`;

const LeftSide = styled.div`
  padding: 5rem;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: space-evenly;
`;

const Title = styled.h2`
  color: #05a0ab;
  font-size: 3rem;
  width: 34rem;
  font-weight: 700;
  margin: 0 auto;

  @media (max-width: 1000px) {
    width: 30rem;
  }

  @media (max-width: 768px) {
    width: 26rem;
  }
`;

const LoginForm = styled.form`
  width: 40rem;
  padding: 3rem;
  border-radius: 5px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 1000px) {
    width: 36rem;
  }
  @media (max-width: 768px) {
    padding: 2rem;
    width: 30rem;
  }
`;

const LoginTitle = styled.h2`
  margin-bottom: 2rem;
  color: #05a0ab;
  font-size: 1.8rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

const Label = styled.label`
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
  font-weight: 500;
  color: #414447;
`;

const Input = styled.input`
  font-size: 1.5rem;
  padding: 1rem;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  outline: none;
`;

const SubmitButton = styled.input`
  font-size: 1.5rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  color: white;
  border: none;
  background-color: #1bb6c1;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  margin-top: 2rem;
`;

const LoginFooter = styled.div`
  margin: 0 auto;
  width: 34rem;
  @media (max-width: 1000px) {
    width: 30rem;
  }
  @media (max-width: 768px) {
    width: 26rem;
  }
`;

const Alert = styled.div`
  padding: 1.5rem 2.5rem;
  background-color: white;
  border-radius: 3px;
  border: 0.5px solid #e4ebf2;
  margin: 1.25rem 0 0 0;
  font-size: 1.4rem;
`;

const Message = styled.p`
  color: #333;
  font-size: 1.6rem;
  font-weight: 700;
`;

export default SignUp;
