import React, { useState, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import { SERVER_URI } from "../../constants";
import Loading from "../../components/utility/Loading";
import LoginImage from "../Login/LoginImage";
import { Link, navigate } from "@reach/router";
import jwtDecode from "jwt-decode";
import { AlertContext } from "../../contexts/AlertContext";

const ResetPassword = (props) => {
  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
  });
  const { addAlert } = useContext(AlertContext);

  const [loading, setLoading] = useState(false);

  const token = getQueryVar("token");
  if (!token || jwtDecode(token).exp * 1000 - Date.now() < 0) {
    return (
      <Wrapper>
        <LeftSide>
          <Title>Reset Password !</Title>
          <LoginForm>
            <LoginTitle>
              Invalid Token ! <br />
            </LoginTitle>
          </LoginForm>
          <Link
            to="/"
            style={{
              display: "block",
              marginBottom: "1rem",
              fontSize: "1.2rem",
              color: "grey",
              textAlign: "center",
            }}
          >
            Back to Login
          </Link>
        </LeftSide>
        <RightSide>
          <LoginImage />
        </RightSide>
      </Wrapper>
    );
  }

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (form.password.length < 6) {
      return addAlert({
        message: "Please Enter Longer Password !",
      });
    }
    if (form.password !== form.confirmPassword) {
      return addAlert({
        message: "Passwords Do Not Match !",
      });
    }
    try {
      setLoading(true);
      await axios.post(
        `${SERVER_URI}/auth/reset-password`,
        {
          ...form,
        },
        { headers: { token: getQueryVar("token") } }
      );
      addAlert({
        message: "Password Reset Successfull. Please Proceed To Login !",
      });
      navigate("/");
    } catch (err) {
      addAlert({ message: "Provide Valid Credentials !" });
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <LeftSide>
        <Title>Reset Password !</Title>
        <LoginForm onSubmit={handleResetPassword}>
          <LoginTitle>
            Please enter your new password. <br />
          </LoginTitle>
          <FormGroup>
            <Label>New Password</Label>
            <Input
              placeholder="Password"
              type="password"
              value={form.password}
              onChange={(e) => {
                setForm({ ...form, password: e.target.value });
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Confirm Password</Label>
            <Input
              placeholder="Confirm Password"
              type="password"
              value={form.confirmPassword}
              onChange={(e) => {
                setForm({ ...form, confirmPassword: e.target.value });
              }}
            />
          </FormGroup>

          {loading ? (
            <Loading />
          ) : (
            <SubmitButton
              value="Submit"
              type="submit"
              onClick={handleResetPassword}
            />
          )}
        </LoginForm>
        <Link
          to="/"
          style={{
            display: "block",
            marginBottom: "1rem",
            fontSize: "1.2rem",
            color: "grey",
            textAlign: "center",
          }}
        >
          Back to Login
        </Link>
      </LeftSide>
      <RightSide>
        <LoginImage />
      </RightSide>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 auto;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 0.8fr 1fr;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const RightSide = styled.div`
  background-color: #fcfcfc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;

  svg {
    width: 60%;
  }
`;

const LeftSide = styled.div`
  padding: 5rem;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: space-evenly;
`;

const Title = styled.h2`
  color: #05a0ab;
  font-size: 3rem;
  width: 34rem;
  font-weight: 700;
  margin: 0 auto;

  @media (max-width: 1000px) {
    width: 30rem;
  }

  @media (max-width: 768px) {
    width: 26rem;
  }
`;

const LoginForm = styled.form`
  width: 40rem;
  padding: 3rem;
  border-radius: 5px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 1000px) {
    width: 36rem;
  }
  @media (max-width: 768px) {
    padding: 2rem;
    width: 30rem;
  }
`;

const LoginTitle = styled.h2`
  margin-bottom: 2rem;
  color: #05a0ab;
  font-size: 1.8rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

const Label = styled.label`
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
  font-weight: 500;
  color: #414447;
`;

const Input = styled.input`
  font-size: 1.5rem;
  padding: 1rem;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  outline: none;
`;

const SubmitButton = styled.input`
  font-size: 1.5rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  color: white;
  border: none;
  background-color: #1bb6c1;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  margin-top: 2rem;
`;

function getQueryVar(varName) {
  // Grab and unescape the query string - appending an '&' keeps the RegExp simple
  // for the sake of this example.
  var queryStr = unescape(window.location.search) + "&";

  // Dynamic replacement RegExp
  var regex = new RegExp(".*?[&\\?]" + varName + "=(.*?)&.*");

  // Apply RegExp to the query string
  var val = queryStr.replace(regex, "$1");

  // If the string is the same, we didn't find a match - return false
  return val == queryStr ? false : val;
}

export default ResetPassword;
