import React, { useState, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import { AuthContext } from "../../contexts/AuthContext";
import { SERVER_URI } from "../../constants";
import Loading from "../../components/utility/Loading";
import LoginImage from "./LoginImage";
import { useGoogleLogin } from "react-google-login";
import { Link } from "@reach/router";
import { AlertContext } from "../../contexts/AlertContext";
import validator from "validator";

const Login = () => {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const [alert, setAlert] = useState(null);

  const [loading, setLoading] = useState(false);
  const { login } = useContext(AuthContext);

  const onSuccess = async (parameter) => {
    const {
      tokenObj: { id_token: googleToken },
    } = parameter;
    try {
      setLoading(true);
      const {
        data: { token, user },
      } = await axios.post(`${SERVER_URI}/auth/googlelogin`, {
        googleToken,
      });
      setAlert({ message: "Logged In Successfully" });
      login({ user, token });
    } catch (err) {
      setLoading(false);
      setAlert({ message: "Provide Valid Credentials" });
    }
  };

  const onFailure = (param) => {};

  const { signIn } = useGoogleLogin({
    onSuccess,
    clientId:
      "132413688174-7p6dnsv57l22q7burrr9kl73oq39g4sj.apps.googleusercontent.com",
    onFailure,
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!validator.isEmail(form.email)) {
      setAlert({ message: "Enter Valid Email" });
    }
    try {
      setLoading(true);
      const {
        data: { token, user },
      } = await axios.post(`${SERVER_URI}/auth/login`, {
        ...form,
      });
      login({ user, token });
      setAlert({ message: "Logged In Successfully" });
    } catch (err) {

     
      setAlert({
        message:
          Object.values(err?.response?.data)[0] || "Provide Valid Credentials",
      });
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <LeftSide>
        <Title>Welcome Back</Title>
        <LoginForm onSubmit={handleLogin}>
          <LoginTitle>
           Intraday Signal Generator  For <br /> Vijay Wealth Community
          </LoginTitle>
          <FormGroup>
            <Label>Email</Label>
            <Input
              placeholder="Email"
              value={form.email}
              onChange={(e) => {
                setForm({ ...form, email: e.target.value });
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Password</Label>
            <Input
              type="password"
              placeholder="Password"
              value={form.password}
              onChange={(e) => {
                setForm({ ...form, password: e.target.value });
              }}
            />
          </FormGroup>

          {loading ? (
            <Loading />
          ) : (
            <>
              <SubmitButton value="Login" type="submit" onClick={handleLogin} />
              <HorizontalLine />
              <GoogleLoginButton
                onClick={(e) => {
                  e.preventDefault();
                  signIn();
                }}>
                Sign In With Google
              </GoogleLoginButton>
            </>
          )}
          {alert && (
            <Alert
              key={alert.id}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}>
              <Message>{alert.message}</Message>
            </Alert>
          )}
        </LoginForm>

        <LoginFooter>
          <Link
            to="/forgot-password"
            style={{
              display: "block",
              marginBottom: "1.5rem",
              fontSize: "1.2rem",
              color: "grey",
              textAlign: "center",
            }}>
            Forgot Your Password ?
          </Link>
           

          {/* <Link
            to="/sign-uP" //todo change signup
            style={{
              display: "block",
              marginBottom: "1rem",
              fontSize: "1.2rem",
              color: "grey",
              textAlign: "center",
            }}>
            Don't Have An Account ? Sign Up
          </Link> */}
        </LoginFooter>
      </LeftSide>
      <RightSide>
        <LoginImage />
      </RightSide>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 auto;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 0.8fr 1fr;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const RightSide = styled.div`
  background-color: #fcfcfc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;

  svg {
    width: 60%;
  }
`;

const LeftSide = styled.div`
  padding: 5rem;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: space-evenly;
`;

const Title = styled.h2`
  color: #05a0ab;
  font-size: 3rem;
  width: 34rem;
  font-weight: 700;
  margin: 0 auto;

  @media (max-width: 1000px) {
    width: 30rem;
  }

  @media (max-width: 768px) {
    width: 26rem;
  }
`;

const LoginForm = styled.form`
  width: 40rem;
  padding: 3rem;
  border-radius: 5px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 1000px) {
    width: 36rem;
  }
  @media (max-width: 768px) {
    padding: 2rem;
    width: 30rem;
  }
`;

const LoginTitle = styled.h2`
  margin-bottom: 2rem;
  color: #05a0ab;
  font-size: 1.8rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

const Label = styled.label`
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
  font-weight: 500;
  color: #414447;
`;

const Input = styled.input`
  font-size: 1.5rem;
  padding: 1rem;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  outline: none;
`;

const SubmitButton = styled.input`
  font-size: 1.5rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  color: white;
  border: none;
  background-color: #1bb6c1;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  margin-top: 2rem;
`;

const GoogleLoginButton = styled.button`
  font-size: 1.5rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  color: white;
  border: none;
  background-color: #1bb6c1;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  margin-top: 2rem;
`;

const LoginFooter = styled.div`
  margin: 0 auto;
  width: 34rem;
  @media (max-width: 1000px) {
    width: 30rem;
  }
  @media (max-width: 768px) {
    width: 26rem;
  }
`;

const FooterText = styled.p`
  font-size: 1.2rem;
  color: grey;
  text-align: center;
`;

const HorizontalLine = styled.hr`
  border: none;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
  padding: 0.8rem 0;
`;

const Alert = styled.div`
  padding: 1.5rem 2.5rem;
  background-color: white;
  border-radius: 3px;
  border: 0.5px solid #e4ebf2;
  margin: 1.25rem 0 0 0;
  font-size: 1.4rem;
`;

const Message = styled.p`
  color: #333;
  font-size: 1.6rem;
  font-weight: 700;
`;

export default Login;
