import { Badge, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { useState, useEffect } from "react";
import { Column } from "primereact/column";
import Layout from "../../components/common/Layout";
import SkeletonLoader from "../../components/common/SkeletonLoader";
import InputGroup from "react-bootstrap/InputGroup";
import Search from "@mui/icons-material/Search";
import moment from 'moment';
import {tertiaryDatabase} from "../../components/utility/firebase"
import { getDatabase, ref, get,query, orderByKey, limitToLast, onValue, off} from "firebase/database";



export default function StockAction() {
  const [isLoading, setIsLoading] = useState(true);
  const [topGainer, setTopGainer] = useState([]);
  const [high52week, setHigh52week] = useState([]);
  const[boillinger15minBuy,setBoillinger15minBuy] = useState([])
  const [low52week, setLow52week] = useState([]);
  const [breakout15min, setBreakout15min] = useState([]);
  const [topLosers, setTopLosers] = useState([]);
  const [searchTopGainersText, setSearchTopGainersText] = useState("");
  const [searchbreakout15min, setSearchbreakout15minText] = useState("");
  const [searchHigh52weekText, setsearchHigh52weekText] = useState("");
  const[searchBoillinger15minBuy,setsearchBoillinger15minBuy] = useState("")
  const [searchLow52weekText, setsearchLow52weekText] = useState("");
  const [searchTopLosersText, setSearchTopLosersText] = useState("");



  //------------firebase-------------------------
  const [prevData, setPrevData] = useState(null); // Add this line to define prevData state


  useEffect(() => {
    // Simulating a delay of 5 seconds
    const delay = 5000; // 5 seconds in milliseconds

    // Set a timeout to stop the loader after the delay
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, delay);

    // Cleanup function to clear the timeout in case the component unmounts before the delay
    return () => clearTimeout(timeoutId);
  }, []); // Empty dependency array ensures that the effect runs only once on mount



  const processData = (newData, alertType, setDataFunction) => {
    if (newData[alertType]) {
      setDataFunction(prevData => {
        const updatedData = [...prevData];
  
        const alertData = newData[alertType];
        const alerts = [];
  
        for (const time in alertData) {
          const scripts = alertData[time].scripts.split(",");
          const triggerPrices = alertData[time].triggerprice.split(",");
  
          for (let i = 0; i < scripts.length; i++) {
            const scriptData = {
              script: scripts[i],
              time: time,
              trigger: triggerPrices[i]
            };
  
            alerts.push(scriptData);
          }
        }
  
        // Sort the array in ascending order based on time (12-hour format)
        alerts.sort((a, b) => {
          const timeA = new Date(`1970-01-01T${a.time}`);
          const timeB = new Date(`1970-01-01T${b.time}`);
          return timeB - timeA;
        });
  
        console.log(alerts);
  
        alerts.forEach(scriptData => {
          const { script, time, trigger } = scriptData;
  
          console.log("\n\n Script: " + script);
  
          const existingIndex = updatedData.findIndex(item => item.script === script);
  
          if (existingIndex !== -1) {
            // Update existing entry
            updatedData[existingIndex] = {
              ...updatedData[existingIndex],
              // Update other properties as needed
            };
  
            console.log("cgat gpt existing data 1 \n\n: " + JSON.stringify(updatedData));
          } else {
            // Add new entry
            updatedData.push({
              script: script,
              time: time,
              trigger: trigger,
              // Add other properties as needed
            });
  
            console.log("cgat gpt existing data 2 \n\n: " + JSON.stringify(updatedData));
          }
        });
  
        updatedData.sort((a, b) => {
          const timeA = new Date(`1970-01-01T${a.time}`);
          const timeB = new Date(`1970-01-01T${b.time}`);
          return timeB - timeA;
        });
  
        return updatedData;
      });
    }
  };
  
  useEffect(() => {
    const todoRef = tertiaryDatabase;
    const refrenceForChartinkAlert = ref(todoRef, 'chartink-alert/');
    const latestDataQuery = query(refrenceForChartinkAlert, orderByKey());
  
    const fetchData = async () => {
      const snapshot = await get(latestDataQuery);
      const newData = snapshot.val();
  
      if (newData) {
        // Process different alert types
     //   processData(newData, 'chartink-alert-5min-volume-spike', setTopGainer);
        processData(newData, 'chartink-alert-15min-breakout', setBreakout15min);
        processData(newData, 'chartink-alert-52week-high', setHigh52week);
        processData(newData, 'chartink-alert-52week-low', setLow52week);
        processData(newData, 'chartink-alert-boilinger-buy-15min', setBoillinger15minBuy);
  
        setPrevData(newData);
      }
    };
  
    const onDataUpdate = snapshot => {
      const newData = snapshot.val();
      
      if (newData) {
        // Process different alert types
      //  processData(newData, 'chartink-alert-5min-volume-spike', setTopGainer);
        processData(newData, 'chartink-alert-15min-breakout', setBreakout15min);
        processData(newData, 'chartink-alert-52week-high', setHigh52week);
        processData(newData, 'chartink-alert-52week-low', setLow52week);
        processData(newData, 'chartink-alert-boilinger-buy-15min', setBoillinger15minBuy);
  
        setPrevData(newData);
      }
    };
  
    fetchData(); // Fetch initial data
  
    const unsubscribe = onValue(latestDataQuery, onDataUpdate);
  
    return () => {
      off(latestDataQuery, onDataUpdate);
      unsubscribe();
    };
  }, []);
  
  
  const filterData = (data, searchText) => {
    return data.filter((item) => {
      // Check if any value in the row contains the search query (case-insensitive)
      for (const key in item) {
        if (
          item[key] &&
          item[key].toString().toLowerCase().includes(searchText.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
  };


  //--------------------------------

  const dataChangeTemplate = (item) => {
    return (
      <Badge pill bg={item.status}>
        {item.dataChange}
      </Badge>
    );
  };

  const changeTemplate = (item) => {
    return <span className={item.status}>{item.trigger}</span>;
  };

  // Function to filter data based on search query

  

  return (
    <>
  <Layout>
<Row className="mt-4">

<Col>
      <Row>
        <Col>
          <h4 className="section-heading text-uppercase mb-4">Stock Action</h4>
        </Col>
      </Row>
      {isLoading ? (
        <SkeletonLoader numColumns={3} />
      ) : (
        <Row>


 {/* ----------------------------Boilinger 15 min buy------------------------------------------------ */}
 <Col xs={12} md={6} lg={4} xl={4} xxl={4}>
            <div className="dashboard-card mb-4">
              <div className="card-header">
                <span className="heading table-heading">Boilinger 15 min buy</span>
                {/* <Link to="/table" className="py-0 weight-600 border-none">
                  View More
                </Link> */}
              </div>
              <div className="card-search">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="topLoser">
                    <Search />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Search Boillinger on 15 min"
                    value={searchBoillinger15minBuy}
                    onChange={(e) => setsearchBoillinger15minBuy(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="table-container">
                <DataTable
                  value={filterData(boillinger15minBuy, searchBoillinger15minBuy)}
                  size="small"
                  sortMode="multiple"
                  removableSort
                  scrollable
                  scrollHeight="15rem"
                >
                 <Column field="script" header="company" sortable></Column>
                  <Column field="time" header="time" sortable></Column>
                  <Column header="trigger" body={changeTemplate}></Column>
                </DataTable>
              </div>
            </div>
          </Col>
          {/* ----------------------------------------------------------------- */}
          <Col xs={12} md={6} lg={4} xl={4} xxl={4}>
            <div className="dashboard-card mb-4">
              <div className="card-header">
                <span className="heading table-heading">15 min breakout</span>
                {/* <Link to="/table" className="py-0 weight-600 border-none">
                  View More
                </Link> */}
              </div>
              <div className="card-search">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="topLoser">
                    <Search />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Search Breakout on 15 min"
                    value={searchbreakout15min}
                    onChange={(e) => setSearchbreakout15minText(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="table-container">
                <DataTable
                  value={filterData(breakout15min, searchbreakout15min)}
                  size="small"
                  sortMode="multiple"
                  removableSort
                  scrollable
                  scrollHeight="15rem"
                >
                 <Column field="script" header="company" sortable></Column>
                  <Column field="time" header="time" sortable></Column>
                  <Column header="trigger" body={changeTemplate}></Column>
                </DataTable>
              </div>
            </div>
          </Col>

         
          <Col xs={12} md={6} lg={4} xl={4} xxl={4}>
            <div className="dashboard-card mb-4">
              <div className="card-header">
                <span className="heading table-heading">52 week high</span>
                {/* <Link to="/table" className="py-0 weight-600 border-none">
                  View More
                </Link> */}
              </div>
              <div className="card-search">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <Search />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Search Top Gainers"
                    value={searchHigh52weekText}
                    onChange={(e) => setsearchHigh52weekText(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="table-container">
                <DataTable
                  value={filterData(high52week, searchHigh52weekText)}
                  size="small"
                  sortMode="multiple"
                  removableSort
                  scrollable
                  scrollHeight="15rem"
                >
                 <Column field="script" header="company" sortable></Column>
                  <Column field="time" header="time" sortable></Column>
                  <Column header="trigger" body={changeTemplate}></Column>
                </DataTable>
              </div>
            </div>
          </Col>
        </Row>
      )}

</Col>
</Row>


<Row className="mt-4">

<Col>
      <Row>
        <Col>
          <h4 className="section-heading text-uppercase mb-4">Stock Action</h4>
        </Col>
      </Row>
      {isLoading ? (
        <SkeletonLoader numColumns={3} />
      ) : (
        <Row>


 {/* ----------------------------Boilinger 15 min buy------------------------------------------------ */}
 <Col xs={12} md={6} lg={4} xl={4} xxl={4}>
            <div className="dashboard-card mb-4">
              <div className="card-header">
                <span className="heading table-heading">Boilinger 15 min buy</span>
                {/* <Link to="/table" className="py-0 weight-600 border-none">
                  View More
                </Link> */}
              </div>
              <div className="card-search">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="topLoser">
                    <Search />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Search Boillinger on 15 min"
                    value={searchBoillinger15minBuy}
                    onChange={(e) => setsearchBoillinger15minBuy(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="table-container">
                <DataTable
                  value={filterData(boillinger15minBuy, searchBoillinger15minBuy)}
                  size="small"
                  sortMode="multiple"
                  removableSort
                  scrollable
                  scrollHeight="15rem"
                >
                 <Column field="script" header="company" sortable></Column>
                  <Column field="time" header="time" sortable></Column>
                  <Column header="trigger" body={changeTemplate}></Column>
                </DataTable>
              </div>
            </div>
          </Col>
          {/* ----------------------------------------------------------------- */}
          <Col xs={12} md={6} lg={4} xl={4} xxl={4}>
            <div className="dashboard-card mb-4">
              <div className="card-header">
                <span className="heading table-heading">15 min breakout</span>
                {/* <Link to="/table" className="py-0 weight-600 border-none">
                  View More
                </Link> */}
              </div>
              <div className="card-search">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="topLoser">
                    <Search />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Search Breakout on 15 min"
                    value={searchbreakout15min}
                    onChange={(e) => setSearchbreakout15minText(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="table-container">
                <DataTable
                  value={filterData(breakout15min, searchbreakout15min)}
                  size="small"
                  sortMode="multiple"
                  removableSort
                  scrollable
                  scrollHeight="15rem"
                >
                 <Column field="script" header="company" sortable></Column>
                  <Column field="time" header="time" sortable></Column>
                  <Column header="trigger" body={changeTemplate}></Column>
                </DataTable>
              </div>
            </div>
          </Col>

         
          <Col xs={12} md={6} lg={4} xl={4} xxl={4}>
            <div className="dashboard-card mb-4">
              <div className="card-header">
                <span className="heading table-heading">52 week high</span>
                {/* <Link to="/table" className="py-0 weight-600 border-none">
                  View More
                </Link> */}
              </div>
              <div className="card-search">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <Search />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Search Top Gainers"
                    value={searchHigh52weekText}
                    onChange={(e) => setsearchHigh52weekText(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="table-container">
                <DataTable
                  value={filterData(high52week, searchHigh52weekText)}
                  size="small"
                  sortMode="multiple"
                  removableSort
                  scrollable
                  scrollHeight="15rem"
                >
                 <Column field="script" header="company" sortable></Column>
                  <Column field="time" header="time" sortable></Column>
                  <Column header="trigger" body={changeTemplate}></Column>
                </DataTable>
              </div>
            </div>
          </Col>
        </Row>
      )}

</Col>
</Row>

</Layout>
      
    </>
  );
}
