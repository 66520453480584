import React, { useState, useContext } from "react";
import styled from "styled-components";
import Loading from "../../../../components/utility/Loading";
import axios from "axios";
import { SERVER_URI } from "../../../../constants";
import { AuthContext } from "../../../../contexts/AuthContext";
import { AlertContext } from "../../../../contexts/AlertContext";

const ChangePassword = () => {
  const [form, setForm] = useState({ password: "", currentPassword: "" });
  const { auth } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { addAlert } = useContext(AlertContext);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    if (form.password.length < 6) {
      return addAlert({ message: "Please Enter Longer Password" });
    }

    try {
      setLoading(true);
      await axios.post(`${SERVER_URI}/auth/change-password`, form, {
        headers: { token: auth.token },
      });
      setForm({ password: "", currentPassword: "" });
      addAlert({ message: "Password Changed Successfully !" });
    } catch (err) {
      addAlert({ message: "Provide Valid Credentials" });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Title>Change Password</Title>
      <FormGroup>
        <Label>Current Password</Label>
        <Input
          type="password"
          placeholder="Current Password"
          value={form.currentPassword}
          onChange={(e) => {
            setForm({ ...form, currentPassword: e.target.value });
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>New Password</Label>
        <Input
          type="password"
          placeholder="New Password"
          value={form.password}
          onChange={(e) => {
            setForm({ ...form, password: e.target.value });
          }}
        />
      </FormGroup>

      {loading ? (
        <Loading />
      ) : (
        <SubmitButton
          value="Update Password"
          type="submit"
          onClick={handleSubmit}
        />
      )}
    </Form>
  );
};

const Form = styled.form`
  width: 40rem;
  border-radius: 5px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 1000px) {
    width: 36rem;
  }
  @media (max-width: 768px) {
    width: 30rem;
  }
  @media (max-width: 360px) {
    width: auto;
  }
`;

const Title = styled.h2`
  margin: 5rem 0 2rem 0;
  color: #1bb6c1;
  font-size: 2.2rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

const Label = styled.label`
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
  font-weight: 500;
  color: #414447;
`;

const Input = styled.input`
  font-size: 1.5rem;
  padding: 1rem;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  outline: none;
`;

const SubmitButton = styled.input`
  font-size: 1.4rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  color: white;
  border: none;
  background-color: #1bb6c1;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  margin-top: 2rem;
`;

export default ChangePassword;
