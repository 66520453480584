import React, { createContext, useState, useCallback, useEffect } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { SERVER_URI } from "../constants";
import { navigate } from "@reach/router";

export const AuthContext = createContext();

let authFromLocalStorage = localStorage.getItem("auth");
if (authFromLocalStorage) {
  authFromLocalStorage = JSON.parse(authFromLocalStorage);
  const decodedToken = jwtDecode(authFromLocalStorage.token);
  if (decodedToken.exp * 1000 - Date.now() < 300000) {
    authFromLocalStorage = null;
    localStorage.clear();
  }
}

export const AuthContextProvider = ({ children }) => {
  const [auth, setAuth] = useState(authFromLocalStorage);

  const login = useCallback((data) => {
    setAuth(data);
    localStorage.setItem("auth", JSON.stringify(data));
  }, []);

  const changeToken = useCallback(
    (token) => {
      setAuth({ ...auth, token });
      localStorage.setItem("auth", JSON.stringify({ ...auth, token }));
    },
    [auth]
  );

  const logout = useCallback(() => {
    setAuth(null);
    navigate("/");
    localStorage.removeItem("auth");
  }, []);

  useEffect(() => {
    if (auth)
      axios
        .get(`${SERVER_URI}/auth/refresh-token`, {
          headers: { token: auth.token },
        })
        .then(({ data }) => {
          changeToken(data.token);
        })
        .catch((err) => {
          logout();
        });
  }, []);

  useEffect(() => {
    if (!auth) return;
    const interval = setInterval(() => {
      axios
        .get(`${SERVER_URI}/auth/refresh-token`, {
          headers: { token: auth.token },
        })
        .then(({ data }) => {
          console.log("Fetched token");
          changeToken(data.token);
        })
        .catch((err) => {
          logout();
        });
    }, 300000);

    return () => {
      clearInterval(interval);
    };
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
