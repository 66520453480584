import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Table from "./Table";
import Loading from "../../components/utility/Loading";
import axios from "axios";
import { AuthContext } from "../../contexts/AuthContext";
import { SERVER_URI } from "../../constants";

const WatchList = () => {
  const [watchList, setWatchList] = useState(null);
  const [companyList, setCompanyList] = useState(null);
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    axios
      .get(`${SERVER_URI}/watch-list`, { headers: { token: auth.token } })
      .then(({ data }) => {
        const finalData = data.watchList.map(
          (each) => each.company.trading_symbol
        );
        setWatchList(finalData);

        axios.get(`${SERVER_URI}/companies/allCompanies`).then(({ data }) => {
          let finalData2 = data.companies.map((each) => ({
            value: each._id,
            label: each.trading_symbol,
          }));

          finalData2 = finalData2.filter(
            (each) => finalData.indexOf(each.label) === -1
          );
          setCompanyList(finalData2);
        });
      });
  }, []);

  if (!watchList || !companyList) {
    return (
      <HomeContainer>
        <Loading />
      </HomeContainer>
    );
  }
  return (
    <HomeContainer>
      <Table
        auth={auth}
        setWatchList={setWatchList}
        watchList={watchList}
        companyList={companyList}
      />
    </HomeContainer>
  );
};

const HomeContainer = styled.div``;

export default WatchList;
