import { Space, Spin,Switch } from "antd";
import { useEffect, useReducer, useRef, useState,useContext } from "react";
import socketIOClient from "socket.io-client";
import styled from "styled-components";
import "./index.scss";
import { AuthContext } from "../../contexts/AuthContext";

import { motion, AnimatePresence } from "framer-motion";
import {app,database} from "../../components/utility/firebase"
import SignalsTable from "./SignalsTable";
import {  ref, onValue, get, child} from "firebase/database";
import TableRow from "./TableRow";

import Toolbar from "./Toolbar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

//const SOCKET_ENDPOINT = "http://139.59.90.225";

const SOCKET_ENDPOINT = "http://localhost:5000";


const Home = () => {

  const { auth } = useContext(AuthContext);
  
  const todoRef = database;
  const refrenceForBBCDB = ref(todoRef, '/users/option_calls/');
  const refrenceForCount = ref(todoRef, 'PCRDB/BBC_COUNT/');
  const [todoList, setTodoList] = useState({ status: "loading", data: null, error: null });
  const [ceCount, setceCount] = useState(0)
  const [peCount, setpeCount] = useState(0)
  const [customSort, setCustomSort] = useState(null);
  const [actionVisible, setActionVisible] = useState(false);
  //let firebase = [];

  // const [data, setData] = useReducer((state, action) => {
  //   switch (action.type) {
  //     case "UPDATE WHOLE":
  //       return action.payload;
  //     case "UPDATE NEW":
  //       if (!state) {
  //         return state;
  //       }
  //       let currentStocksData = [...state];
  //       const tick = action.payload;
  //       for (let i = 0; i < currentStocksData.length; i++) {
  //         for (let j = 0; j < tick.length; j++) {
  //           if (
  //             currentStocksData[i].instrument_token === tick[j].instrument_token
  //           ) {
  //             currentStocksData[i] = { ...currentStocksData[i], ...tick[j] };
  //             break;
  //           }
  //         }
  //       }
  //       return currentStocksData;
  //     case "SORT":
  //       if (customSort) {
  //         return state;
  //       }
  //       if (!state) {
  //         return state;
  //       }
  //       let currentStocks = [...state];
  //       currentStocks.sort((a, b) => {
  //         if (!a.name && !b.name) {
  //           return 0;
  //         }
  //         if (a.name && !b.name) {
  //           return -1;
  //         }

  //         if (!a.name && b.name) {
  //           return 1;
  //         }

  //         if (a.volume > 1 && a.volume > b.volume) {
  //           return -1;
  //         } else if (b.volume > 1 && b.volume > a.volume) {
  //           return 1;
  //         }

  //         if (a.volumeMultiplier > b.volumeMultiplier) {
  //           return -1;
  //         } else if (a.volumeMultiplier < b.volumeMultiplier) {
  //           return 1;
  //         }
  //         const signalGeneratedDateOfA = new Date(
  //           a.signalGeneratedDate
  //         ).getTime();
  //         const signalGeneratedDateOfB = new Date(
  //           b.signalGeneratedDate
  //         ).getTime();
  //         if (signalGeneratedDateOfA > signalGeneratedDateOfB) {
  //           return -1;
  //         }
  //         if (signalGeneratedDateOfA < signalGeneratedDateOfB) {
  //           return 1;
  //         }
  //         return 0;
  //       });
  //       return currentStocks;
  //     case "CUSTOM SORT":
  //       let currentData = [...state];
  //       const mode = action.payload.mode;
  //       const identifier = action.payload.for;
  //       currentData.sort((a, b) => {
  //         if (mode === "ascending") {
  //           if (a[identifier] > b[identifier]) {
  //             return -1;
  //           } else return 0;
  //         } else {
  //           if (a[identifier] < b[identifier]) {
  //             return -1;
  //           } else return 0;
  //         }
  //       });
  //       return currentData;
  //     default:
  //       return state;
  //   }
  // }, null);


  const socketRef = useRef();
let sahil = [];
  useEffect(() => {
    // if (customSort) {
    //   setData({
    //     type: "CUSTOM SORT",
    //     payload: customSort,
    //   });
    //   return;
    // }
    

    
//Firebase code
 
const listener = onValue(refrenceForBBCDB, (snapshot) => {
  //const data = snapshot.val();
  const todos = snapshot.val();
  console.log(todos)


        const todoList = [];
      for (let id in todos) {

        console.log(todos[id])
        //  todos[id].stopLoss = todos[id].
        todoList.push(todos[id])
        
        sahil.push(todos[id])
       
      }
      console.log(sahil)
     
   
      setTodoList(sahil.sort((a, b) => a.signalGeneratedDate > b.signalGeneratedDate ? -1 : 1));
     // setData()
     // setData({ type: "SORT" , payload : sahil.reverse()});

      console.log("*******************************")
  
});


return listener;
  




  }, []);



  // useEffect(() => {
  //   if (customSort) {
  //     setData({
  //       type: "CUSTOM SORT",
  //       payload: customSort,
  //     });
  //     return;
  //   }
  //   setData({ type: "SORT" });
  //   const interval = setInterval(() => {
  //     setData({ type: "SORT" });
  //   }, 45000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [customSort]);

 


  if (!todoList) {
    return (
      <Space
        style={{ height: "100vh", width: "100%", justifyContent: "center" }}
        align="center"
        direction="vertical">
        <Spin size="large" />
      </Space>
    );
  }
 const showCe = true;

 
  switch (todoList.status) {
    case "loading":
      return null; // hides component, could also show a placeholder/spinner
    case "error":
      return (
        <div class="error">
          Failed to retrieve data: {todoList.error.message}
        </div>
      );
  }

 
      
    
  

  
  return (
    <div className="home">

    {/* <Toolbar
    {...{
      showCe : true,
      setData,
      selectedMonthYearCombo,
      possibleMonthYearCombo,
      handleMonthYearComboChange,
      CE,
      PE,
      signals //way2 if ce,pe doesnt work
    }}
  /> */}

<Space align="end" style={{ width: "20%", justifyContent: "space-around" }}>

{/* <Switch
          style={{ backgroundColor: "#1890ff" }}
          onChange={alterData}
          checked={showCe}
          checkedChildren="CE"
          unCheckedChildren="PE"
        /> */}

        </Space>

    <SignalsTable {...{ signals :todoList,showCe }} />


    </div>
  );
}

const ActionContainer = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ActionTrigger = styled.p`
  font-size: 1.4rem;
  padding: 1rem;
  border-radius: 10000px;
  height: 5rem;
  width: 5rem;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e4ebf2;
`;



const Action = styled.li`
  padding: 1rem 2rem;
  font-size: 1.4rem;
  color: #333;
  background-color: white;
  transition: background-color 0.2s;

  &:hover {
    background-color: #e4ebf2;
  }
`;

const Container = styled.div`
  background-color: white;
  padding-bottom: 3rem;
  position: relative;
`;

const TableWrapper = styled.div`
  padding: 5.5rem 0 0 0;
  max-width: 120rem;
  margin: 0 auto;
  margin-bottom: 6rem;
  overflow-x: auto;
  white-space: nowrap;
`;

const TableContent = styled.div`
  width: 120rem;
  overflow-x: scroll;
  overflow-y: hidden;
  overflow: hidden;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 24rem 28rem 14rem 14rem  ;
  grid-gap: 5rem;
  padding: 0.2rem 3rem;
  background-color: #f0f5f8;
  justify-content: left;
`;


const ActionsWrapper = styled(motion.ul)`
  padding-left: 0;
  list-style: none;
  margin-bottom: 1rem;
  border: 0.5px solid #e4ebf2;
`;

const TableHeaderItem = styled.p`
  padding: 1rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: left;
  justify-content: left;
  color: #6c7887;
`;

const TableBody = styled.div``;

const PagesContainer = styled.div`
  display: flex;
  margin-bottom: 3rem;
  justify-content: center;
`;

const Page = styled.p`
  padding: 1rem 1.5rem;
  margin: 0 0.5rem;
  border-radius: 0.2rem;
  background-color: ${({ active }) => (active ? "#7c7e80" : "#f0f5f8")};
  color: ${({ active }) => (active ? "white" : "#6c7887")};

  font-size: 1.4rem;
  cursor: pointer;
`;

const ChevronDown = (
  <svg
    style={{ marginLeft: "1rem" }}
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none">
    <path
      d="M10.293 0.292969L6 4.58597L1.707 0.292969L0.292999 1.70697L6 7.41397L11.707 1.70697L10.293 0.292969Z"
      fill="#6c7887"
    />
  </svg>
);

const ChevronUp = (
  <svg
    style={{ marginLeft: "1rem" }}
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none">
    <path
      d="M1.707 7.41406L6 3.12106L10.293 7.41406L11.707 6.00006L6 0.293064L0.293 6.00006L1.707 7.41406Z"
      fill="#6c7887"
    />
  </svg>
);

export default Home;