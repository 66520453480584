import React from "react";
import styled from "styled-components";
import Table from "./Table";
import "./index.scss";

const Home = () => {
  return (
    <HomeContainer>

   
      <Table />
     
    </HomeContainer>
  );
};

const HomeContainer = styled.div``;

export default Home;
