import React from "react";
import styled from "styled-components";

const TopTable = ({ topTableData }) => {
  const getData = () => {
    return {
      Nifty: topTableData.find((value) => value.name === "Nifty Futures"),
      CRUDE: topTableData.find((value) => value.name === "CRUDE"),
      GOLD: topTableData.find((value) => value.name === "GOLD"),
      "USD/INR": topTableData.find((value) => value.name === "USD/INR"),
      VIX: topTableData.find((value) => value.name === "VIX"),
    };
  };

  if (!topTableData) {
    return <Container></Container>;
  }
  const data = getData();
  return (
    <Container>
      <LeftTable>
        <LeftTableTitle>Nifty Future</LeftTableTitle>
        <LeftTableBody>
          <LeftTableRow>
            <LeftTableTH>Open</LeftTableTH>
            <LeftTableTD>{data.Nifty && data.Nifty.ohlc.open}</LeftTableTD>
          </LeftTableRow>
          <LeftTableRow>
            <LeftTableTH>High</LeftTableTH>
            <LeftTableTD>{data.Nifty && data.Nifty.ohlc.high}</LeftTableTD>
          </LeftTableRow>
          <LeftTableRow>
            <LeftTableTH>Low</LeftTableTH>
            <LeftTableTD>{data.Nifty && data.Nifty.ohlc.low}</LeftTableTD>
          </LeftTableRow>
          <LeftTableRow>
            <LeftTableTH>LTP</LeftTableTH>
            <LeftTableTD>{data.Nifty && data.Nifty.last_price}</LeftTableTD>
          </LeftTableRow>
        </LeftTableBody>
      </LeftTable>
      <RightTable>
        <TableHeader>
          <TableRow>
            <TableHead>Component</TableHead>
            <TableHead>LTP</TableHead>
            <TableHead>%Change</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data["USD/INR"] && (
            <TableRow>
              <TableData style={{ fontWeight: 500 }}>USD/INR</TableData>
              <TableData>{data["USD/INR"].last_price}</TableData>
              <TableData>
                {data["USD/INR"].change && data["USD/INR"].change.toFixed(2)}
              </TableData>
            </TableRow>
          )}
          {data["CRUDE"] && (
            <TableRow>
              <TableData style={{ fontWeight: 500 }}>CRUDE</TableData>
              <TableData>{data["CRUDE"].last_price}</TableData>
              <TableData>
                {data["CRUDE"].change && data["CRUDE"].change.toFixed(2)}
              </TableData>
            </TableRow>
          )}
          {data["GOLD"] && (
            <TableRow>
              <TableData style={{ fontWeight: 500 }}>GOLD</TableData>
              <TableData>{data["GOLD"].last_price}</TableData>
              <TableData>
                {data["GOLD"].change && data["GOLD"].change.toFixed(2)}
              </TableData>
            </TableRow>
          )}
          {data["VIX"] && (
            <TableRow>
              <TableData style={{ fontWeight: 500 }}>VIX</TableData>
              <TableData>{data["VIX"].last_price}</TableData>
              <TableData>
                {data["VIX"].change && data["VIX"].change.toFixed(2)}8
              </TableData>
            </TableRow>
          )}
        </TableBody>
      </RightTable>
    </Container>
  );
};

const Container = styled.div`
  padding: 5rem 7.5rem;
  display: flex;
  align-items: stretch;
  justify-content: center;
  /* margin-top: 2.5rem; */
  /* padding-bottom: 5.5rem; */
  min-height: 20rem;
  border-bottom: 1px solid #e4ebf2;

  @media (max-width: 676px) {
    flex-direction: column;
    padding: 5rem 0;
    /* padding-bottom: 5.5rem; */
    align-items: center;
  }
`;

const LeftTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1.4rem;

  background-color: white;
  border: 1px solid #e4ebf2;
  border-radius: 2px;

  @media (max-width: 676px) {
    width: 38rem;
    margin-bottom: 5rem;
  }

  @media (max-width: 445px) {
    width: 30rem;
  }
`;

const LeftTableTitle = styled.p`
  display: inline-block;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 21px;
  align-self: stretch;
  padding: 1rem 0;
  border-bottom: 0.5px solid #e4ebf2;
  text-align: center;
  color: #889db3;
`;

const LeftTableBody = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const LeftTableRow = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 0.5px solid #e4ebf2;

  &:last-child {
    border-bottom: none;
  }
`;

const LeftTableTH = styled.p`
  margin: 0;
  width: 8rem;
  padding: 1rem;
  text-align: center;
  font-size: 1.4rem;
  color: #10223b;
  border-right: 0.5px solid #e4ebf2;
`;

const LeftTableTD = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 18px;
  width: 8rem;
  padding: 1rem;
  color: #10223b;
  text-align: center;
`;

const RightTable = styled.table`
  background-color: white;
  border: 1px solid #e4ebf2;
  border-radius: 2px;

  border-spacing: 0;
  margin: 0 1.4rem;
  @media (max-width: 676px) {
    width: 38rem;
  }
  @media (max-width: 445px) {
    width: 30rem;
    padding: 1.5rem;
  }
`;

const TableHeader = styled.thead`
  border-bottom: 0.5px solid #0085fe;
`;

const TableHead = styled.th`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 21px;
  text-align: center;
  color: #889db3;
  width: 10rem;
  padding: 1rem;
  border-right: 0.5px solid #e4ebf2;
  border-bottom: 0.5px solid #e4ebf2;

  &:last-child {
    border-right: none;
  }

  @media (max-width: 445px) {
    padding: 1rem;
    /* padding-right: 0.5rem; */
  }
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr`
  &:last-child td {
    border-bottom: none;
  }
`;

const TableData = styled.td`
  padding: 1rem;
  font-size: 1.4rem;
  line-height: 18px;
  text-align: center;
  border-right: 0.5px solid #e4ebf2;
  border-bottom: 0.5px solid #e4ebf2;

  &:last-child {
    border-right: none;
  }

  @media (max-width: 445px) {
    padding: 1rem;
  }
`;

export default TopTable;
